import React from 'react';
import './index.scss';

export default class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }
  componentDidUpdate() {

  }
  componentWillUnmount() {

  }
  render() {
    return (
      <div className='toast-panel'>邮件发送成功，我们将在第一时间与您取得联系</div>
    )
  }
}